
.autolink-grid {
    div.k-grid-header {
        margin-left: 1px;
    }
    td.k-table-td {
        border-left: solid 1px #e0e0e0 !important;
    }

    .MuiAutocomplete-root {
        input {
            padding: 0px 5px 0px 0px !important;
            font-size: 11px !important;
        }
    }
}