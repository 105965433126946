@import './colors.scss';

.grid {
  &_title {
    font-size: 1.75rem;
    line-height: 2.0625rem;
    font-weight: 500;
    color: black;
  }

  &_table {
    border: none;
    height: calc(100vh - 135px);
  }
}

th.k-header {
  font-weight: bold;
  color: #404040;
  font-family: 'Rubik';
  font-size: $mainFontSize;
}

.k-grid-toolbar {
  padding: 14px 0px !important;
  border: none;
  overflow: visible;
  display: block;
}

.k-grid-header-wrap {
  border-right: none;
}

.k-grid-header thead>tr:not(:only-child)>th {
  border-left-color: rgba(0, 0, 0, 0) !important;
}

.k-grid th:first-child,
.k-grid td:first-child {
  border-left-width: 1px;
}

.overflow-visible {
  overflow: visible !important;
}

.k-grid th,
.k-grid td {
  padding: 0px 0px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  // padding-right: 10px;
}

.k-grid .k-grid-edit-row td:not(.k-calendar-td),
.k-grid .k-command-cell,
.k-grid .k-edit-cell {
  padding: 0px;
}

.discount-editor-array-container {

  tr.k-master-row,
  td {
    padding: 0px;
    padding-right: $mainMargin;
  }
}

.number-cell-data {
  text-align: right !important;
  padding-right: $mainMargin;
}

.k-checkbox:checked {
  border-color: $primarycolor !important;
  color: #fff !important;
  background-color: $primarycolor !important;
}

.k-checkbox:indeterminate {
  border-color: $primarycolor !important;
  color: #fff !important;
  background-color: $primarycolor !important;
}

// .k-selected{
//   background-color: $grey-color !important;
// }
.k-list .k-selected {
  color: $primarycolor !important;
  background-color: $primarycolor_background !important;
}

.rounded_status {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  border-radius: 50%;

  &.Non {
    background-color: lightgrey;
  }

  &.Sim {
    background-color: $modificationcolor;
  }

  &.Opt {
    background-color: $informationcolor;
  }

  &.Con,
  &.activated {
    background-color: $validationcolor;
  }

  &.Ann {
    background-color: $errorcolor;
  }

  &.Can,
  &.disabled {
    background-color: $errorcolor;
  }
}

[class^="status_estimate_"] {
  padding: 4px 8px;
  border-radius: 5px;
  color: white;
  opacity: 0.9;
}

.status_estimate_ {

  &accepted,
  &advanced,
  &sent,
  &due {
    background-color: $validationcolor;
  }

  &draft {
    background-color: $modificationcolor;
  }

  &partialinvoiced,
  &invoiced,
  &read {
    background-color: $informationcolor;
  }

  &refused,
  &expired,
  &cancelled,
  &late {
    background-color: $errorcolor;
  }

    &AgencyValidated {
      background-color: $informationcolor;
    }

    &ClientValidated {
      background-color: $validationcolor;
    }

    &Canceled {
      background-color: $errorcolor;
    }

    &Archived {
      background-color: lightgrey;
    }

    &Draft {
      background-color: lightgrey;
    }

    &Valuation {
      background-color: $modificationcolor;
    }
}

// td.column_Status.cellule_Optionné span{
//   padding: 6px 10px;
//   background-color: $informationcolor;
//   border-radius: 13px;
//   color: white;
// }
// td.column_Status.cellule_Aucun span{
//   padding: 6px 10px;
//   background-color: lightgrey;
//   border-radius: 13px;
//   color: white;
// }
// td.column_Status.cellule_Simulé span{
//   padding: 6px 10px;
//   background-color: $modificationcolor;
//   border-radius: 13px;
//   color: white;
// }
// td.column_Status.cellule_Confirmé span{
//   padding: 6px 10px;
//   background-color: $validationcolor;
//   border-radius: 13px;
//   color: white;
// }
// td.column_Status.cellule_Validé span{
//   padding: 6px 10px;
//   background-color: $validationcolor;
//   border-radius: 13px;
//   color: white;
// }
// td.column_Status.cellule_Annulé span{
//   padding: 6px 10px;
//   background-color: $errorcolor;
//   border-radius: 13px;
//   color: white;
// }

.status_message_base {
  padding: 3px 6px;
  border-radius: 5px;

  color: white;
}

.column_Status {

  &.ValueCell_Opted {
    @extend .status_message_base;
    background-color: $informationcolor;
  }

  &.ValueCell_None {
    @extend .status_message_base;
    background-color: lightgrey;
  }

  &.ValueCell_Simulated {
    @extend .status_message_base;
    background-color: $modificationcolor;
  }

  &.ValueCell_Confirmed {
    @extend .status_message_base;
    background-color: $validationcolor;
  }

  &.ValueCell_Valid {
    @extend .status_message_base;
    background-color: $validationcolor;
  }

  &.ValueCell_Cancelled {
    @extend .status_message_base;
    background-color: $errorcolor;
  }

  &.ValueCell_Opted {
    @extend .status_message_base;
    background-color: $informationcolor;
  }
}

.AdwAutocomplete-column_Status {
  span.column_Status {
    font-size: 12px !important;
  }
}

.input-column_Status {
  input {
    color: transparent;
  }
}

div.MuiAutocomplete-option.StatusEditor-option {
  padding-left: 5px;
} 

.state_message {

  &_Confirmed {
    @extend .status_message_base;
    background-color: $validationcolor;
  }

  &_Bound {
    @extend .status_message_base;
    background-color: $validationcolor;
  }

  &_None {
    @extend .status_message_base;
    background-color: lightgrey;
  }

  &_Cancelled {
    @extend .status_message_base;
    background-color: $errorcolor;
  }
}

.status_agreement {

  &.activated {
    @extend .status_message_base;
    line-height: initial;
    background-color: $validationcolor;
  }

  &.disabled {
    @extend .status_message_base;
    line-height: initial;
    background-color: $errorcolor;
  }
}

.status_agreement_lock {
  color: $dark-grey;
}

.k-grid-header-wrap {
  border-top-width: 1px;
}

.k-grid-header .k-header.k-grid-header-sticky,
.k-grid-header .k-filter-row .k-grid-header-sticky,
.k-grid-content-sticky,
.k-grid-footer-sticky {
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-left-width: 1px;
}

th.k-header {
  font-weight: 500;
}


.k-grid th.k-grid-myselection-cell,
.k-grid td.k-grid-myselection-cell,
.k-grid.custom-tree-list th.k-grid-header-sticky,
.k-grid.custom-tree-list td.selectable-true {
  text-align: center;
  vertical-align: middle;
  padding: 0px !important;
  left: 0px;

  input {
    vertical-align: middle;
  }
}