
  #content {
    width: 100%;
  }
  .react-grid-layout {
    background: #fff;
  }
  .layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
  }
  .columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
  }
  .react-grid-item {
    /* -webkit-box-shadow: 9px 9px 10px -7px rgba(71,71,71,1); */
    /* -moz-box-shadow: 9px 9px 10px -7px rgba(71,71,71,1); */
    /* box-shadow: 9px 9px 10px -7px rgba(71,71,71,1); */
    box-sizing: border-box;
    background-color: #F4F9FF !important;
  }
  .react-grid-item:not(.react-grid-placeholder) {
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    /* border: 1px solid #DDDDDD; */
    border-radius: 8px; 
  }
  .react-grid-item.resizing {
    opacity: 0.9;
  }
  .react-grid-item.static {
    background: #cce;
  }
  .react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
  }
  .react-grid-item .minMax {
    font-size: 12px;
  }
  .react-grid-item .add {
    cursor: pointer;
  }
  .react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  li b {
    font-size: 19px;
    line-height: 14px;
  }
  