
/** Used to define container behavior: width, position: fixed etc... **/
/* .Toastify__toast-container {
} */

/** Used to define the position of the ToastContainer **/
/* .Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
} */

/** Classes for the displayed toast **/
.Toastify__toast {
    color: #696867 !important;
}
/* .Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
} */
.Toastify__toast--info {
    background: #DEEDFD !important;
}
.Toastify__toast--success {
    background: #E3F4EA !important;
}
.Toastify__toast--warning {
    background: #FDEBE0 !important;
}
.Toastify__toast--error {
    background: #FAE2E4 !important;
}
/* .Toastify__toast-body {
} */

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    color: #696867 !important;
}
/* .Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
} */

.Toastify__toast {
    min-height: unset !important;
    border-radius: 2px !important;
}

.Toastify__progress-bar {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

/** Classes for the progress bar **/
/* .Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
} */

.title-notification {
    font-weight: bold;
    color: #4c4c4c;
}

.img-back-notif-info {
    background: #1A81EF;
}

.img-back-notif-success {
    background: #3AB36E;
}

.img-back-notif-warning {
    background: #F27629;
}

.img-back-notif-error {
    background: #DA3240;
}

.img-notification {
    margin-top: 9px;
    color: white;
    float: left;
    padding: 5px;
    border-radius: 15px;
    margin-right: 10px;
    margin-left: 5px;
}