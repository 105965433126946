.home_container{
    min-height: calc(100vh - 76px);
    display: flex;
    .widgets_container{
        width: 100%;
        float: right;
        background-color: #FFFFFF;
        .title{
            font-weight: 500;
            color: black;
        }
    }
    .calendar_container{
        width: 455px;
        background-color: #F0F0F0;
        float: right;
        .title{
            margin-bottom: 20px;
            line-height: 1.125rem;
        }
    }
}
.react-grid-item-content{

    .k-grid.k-grid-md.k-treelist.treelist_telerik {
        border-radius: 8px;
    }

    // padding: 10px !important;
    height: calc(100%);
    padding-top: $mainHeight;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;
    overflow: hidden;
    .k-table-row.k-filter-row {
        display: none;
    }

    .CrossedTableTelerikTree-container {
        > div {
          height: 100%;
        }
      }
}

span.react-resizable-handle.react-resizable-handle-se {
    position: absolute;
    right: 0;
    bottom: 0;
}