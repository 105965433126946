@import './colors.scss';
@import './datePicker.scss';
@import './pivotGrid.scss';
@import './deviceTheme.scss';
@import './baseTheme.scss';
@import './containerComponent.scss';
@import './groupingRows.scss';
@import './kendo.scss';
@import './bubbleDialog.scss';

/* Style général de la scrollbar verticale */
::-webkit-scrollbar {
    width: 12px;
    /* Largeur de la scrollbar verticale */
    height: 12px !important;
    /* Hauteur de la scrollbar horizontale */
}

/* Fond de la scrollbar verticale */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Couleur de fond */
    border-radius: 10px;
}

/* Fond de la scrollbar horizontale */
::-webkit-scrollbar-track:horizontal {
    background: #f1f1f1;
    /* Couleur de fond */
    border-radius: 10px;
}

/* Barre de défilement verticale */
::-webkit-scrollbar-thumb {
    background-color: #a3a3a3;
    /* Couleur de la barre */
    border-radius: 10px;
    border: 3px solid #f1f1f1;
    /* Espacement autour de la barre */
}

/* Barre de défilement horizontale */
::-webkit-scrollbar-thumb:horizontal {
    background-color: #a3a3a3;
    /* Couleur de la barre */
    border-radius: 10px;
    border: 2px solid #f1f1f1;
    /* Espacement autour de la barre */
}

/* Couleur de la barre au survol (vertical et horizontal) */
::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}

span.footerCellList {
    max-height: $mainRowMargin;
    display: block;
}

.element-active {
    background-color: green;
    display: inline-flex;
    height: 10px;
    width: 10px;
    border-radius: 5px;
}

.element-inactive {
    cursor: pointer;
    background-color: red;
    display: inline-flex;
    height: 10px;
    width: 10px;
    border-radius: 5px;
}

html {
    font-size: $mainFontSize;
}

body {
    line-height: normal !important;
    margin: 0;
    font-family: 'Rubik', sans-serif !important;
    //font-size: $mainFontSize;
}

.EditingInlineCell {
    label.MuiFormLabel-root {
        display: none;
    } 
    fieldset legend span {
        display: none;
    }
}

.file-description-text {
    font-size: 11px !important;
}

.k-pdf-export {
    .dashboard-container .dashboard-header {
        grid-template-columns: 1fr 1.2fr 0.9fr 0.9fr;
    }
    .react-resizable-handle,
    .hide-pdf {
        display: none !important;
    }

    .show-pdf {
        display: block !important;
    }

    .title-CustomLayout {
        &.DraggableArea {
          background: transparent !important;
        }
    }

    .k-editor {
        .k-editor-toolbar {
            display: none;
        }
    }
}

.frozen-cell-td,
.k-grid-table {
    span.k-treelist-toggle {
        cursor: pointer;

        &:hover {
            background-color: #0000001e;
            border-radius: 10px;
        }
    }
}

.k-table-md,
.k-grid {
    font-size: $mainFontSize !important;
}

.react-terminal-wrapper {
    padding-top: 40px !important;
    padding-left: $mainMargin !important;
    padding-right: $mainMargin !important;
    padding-bottom: $mainMargin !important;
    height: calc(100% - 64px);

    &:before {
        display: none !important;
    }

    .react-terminal {

        height: 100% !important;
    }

    .react-terminal-line {
        white-space: normal;
    }
}

.no-shadow {
    box-shadow: none !important;

    &:hover,
    span,
    div {
        box-shadow: none !important;
    }
}

.MuiIcon-root {
    font-size: 1.8rem !important;
}

.small-icon.MuiIcon-root {
    font-size: 18px !important;
}

.picto .MuiIcon-root {
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;
}

.profile-tab-component {
    display: flex;
    position: absolute;
    right: 0;
    align-items: center;

    .notification-icon-component {
        margin-right: 5px;
        margin-left: 5px;
    }
}

.MuiButton-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-radius: 2px !important;
}

header.MuiPaper-root {
    box-shadow: none !important;
    border-bottom: 1px solid $mainBorder !important;
}

/** TOOLBAR ADWONE */
.appBarShift,
.navigation_drawer_header {
    height: $mainToolBarHeight;
}

.MuiToolbar-regular.appBarShift {
    padding-left: $mainMargin !important;
}

.toolbarcontent {
    height: $mainToolBarHeight * 0.60;
}

$paddingGridContainer : ($mainMargin + $mainToolIconsWidth);

.icons-panel {
    height: calc(100vh - (#{$mainToolBarHeight} + #{$mainMargin} + 1px)) !important;
}

.main-configuration-panel>.MuiPaper-root {
    height: 100% !important;
}

.custom-toolbar-adwtelerikgrid {
    // margin-bottom: 30px;
    margin-bottom: $mainMargin;
    position: relative;

    &-fixed {
        @extend .custom-toolbar-adwtelerikgrid;
        height: $mainHeight;
    }
}

.main-configuration-panel,
.main-configuration-panel>.MuiPaper-root {
    z-index: 1000 !important;
    margin-top: $mainToolBarHeight;
    box-shadow: none;

    // border-top: solid 1px $mainBorder;
    .MuiBackdrop-root {
        top: $mainToolBarHeight;
    }
}

.main-configuration-panel>.MuiPaper-root {}

.icons-panel {
    .icon-panel-item {
        >div>button {
            width: $mainHeight;
            height: $mainHeight;
        }

        margin: auto;
        width: $mainHeight;
        height: $mainHeight;
    }

    display: grid;
    align-content: flex-start;
    border: solid 1px $mainBorder;
    border-top: 0px;
    background: white;
    position: absolute;

    width: $mainToolIconsWidth;
    // border-top-left-radius: 8px;
    // border-top-right-radius: 8px;
    visibility: visible;
    right: $paddingGridContainer * -1;
    padding-top: $mainMargin;

    &:not(.icons-integrated) {
        top: $mainMargin * -1;
    }

    &.icons-integrated {
        // top:  -1px;
        left: $mainToolIconsWidth * -1;
    }

    &-badge-container {
        position: relative;

        .k-badge {
            width: 10px;
            height: 10px;
            background-color: $dark-blue;
            border-color: $dark-blue;

            &.k-top-start {
                top: 2px;
                left: 3px;
            }
        }
    }
}

.grid_panel_container {
    padding-right: $paddingGridContainer;
}

.grid_base_container {
    height: calc(100vh - 70px);
}

.grid_container {
    @extend .grid_panel_container;
    padding-top: $mainMargin;
    padding-left: $mainMargin;
    padding-bottom: $mainMargin;
    display: flex;
    flex-grow: 1;
    height: calc(100vh - 95px);

    background-color: white;

    .grid_body {
        width: 100%;
    }

    &.no_panel {
        padding-right: $mainMargin;
    }
}

.EstimateDetails {
    .FooterCellOptions {
        display: none;
    }
}

.configuration-multiselect-popup {
    transform: translateY(-30px);

    .k-treeview {
        max-height: 350px !important;
    }
}

.grid_table,
.k-gantt,
.treelist_telerik,
.custom-tree-list {

    .k-grid-header .k-header,
    .k-grid td:not([role="presentation"]) {
        vertical-align: middle !important;
    }

    tr.k-master-row {

        td:not(.k-calendar-td),
        th {
            padding-top: 0;
            padding-bottom: 0;
            // height: $mainHeight - 1;
            margin: auto;
        }
    }
}

.grid_table,
.treelist_telerik {

    th.k-header,
    td[role="columnfooter"],
    .number-cell-data {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.usage-icons-title {
    float: right;
}

.usage-icons {
    display: flex;

    .k-badge-container {
        margin-left: $mainMargin*2;
        color: $mainMenuColor;

        .k-badge {
            background-color: $validationcolor;
            border-color: $validationcolor;
            z-index: 10;
        }

        .empty-badge {
            background-color: $mainBorder;
            border-color: transparent;
        }

        .k-badge-edge.k-badge-md {
            font-weight: normal;
            height: 25px;
            width: 25px;
        }
    }
}

.tooltip {
    color: white;
    width: 100%;
    padding: 5px;

    span {
        font-weight: normal;
        font-size: $mainFontSize;
        font-family: $mainFontNumber !important;
    }
}

.k-master-row,
.DiscountGrid {

    .number-cell,
    .k-numerictextbox input {
        font-family: $mainFontNumber !important;
    }
}

.MuiDialogContent-root,
.dialog_actions {
    padding: 0 $marginDialog !important;
}

.dialog_actions {
    padding-bottom: $marginDialog !important;
    z-index: 10000;
}

.margin_top_dialog_export {
    margin-top: $mainRowMargin;
}

.margin_bottom_dialog_export {
    margin-bottom: $mainRowMargin;
}

.reset-button {
    position: absolute !important;
    left: $marginDialog !important;
}

.MuiDialog-paperWidthLg {
    min-width: 950px;
}

.form-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
}

span.MuiIconButton-label,
span.material-icons {
    font-family: 'Material Icons' !important;
}

span.k-icon {
    font-family: 'WebComponentsIcons' !important;
}

.custom_daterangepicker {}

input,
.k-textbox,
.k-input,
.MuiListItemText-primary,
.MuiInputLabel-outlined,
.MuiListItem-button,
.MuiAutocomplete-option,
.MuiButton-label,
.vertexauto-option,
.MuiStepLabel-label,
.k-dateinput-wrap,
.MuiAutocomplete-groupLabel,
.MuiListItem-button,
.MuiFormControlLabel-label,
p,
.k-calendar-title,
a.MuiLink-root,
.k-calendar-th {
    font-family: $mainFont !important;
    font-size: $mainFontSize !important;
}

.k-chart-surface {
    &:focus-visible {
        outline: unset !important;
    }
}

.MapContainer-geojson {
    .k-map {
        height: 100%;
    }
}

.menu-kendo {
    min-width: 100px;
    color: $dark-blue !important;
    svg {
        color: $dark-blue !important;
    }
    fieldset, legend, label {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
        font-weight: 500 !important;
        font-size: 0.875rem !important;
        color: $dark-blue !important;
        border-color: $dark-blue !important;
        border-radius: 2px !important;
    }
}

ul.menu-kendo {
    span {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
    }
    color: $dark-blue !important;
    border: 1px solid $mainBorder;
    border-color: $dark-blue;
    height: $mainHeight;

    &:hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }

    > li {
        &:hover {
            background-color: unset !important;
        }
        > span {
            padding: 0px 10px;
        }
    }
}

.MuiFormLabel-root,
.MuiChip-label,
text,
h1,
h2,
h3,
h4,
h5,
h6,
span {
    font-family: $mainFont !important;
}

.MuiAutocomplete-listbox {
    >ul {
        padding-left: 0px;
    }
}

.k-dropdownlist {
    font-size: $mainFontSize !important;
}

.checkbox-group-root {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1px;

    > {
        grid-column: 1 / 3;
        grid-row: 1;
    }
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) !important;
    font-size: $mainFontSize*0.85 !important;
    z-index: 1;
}

.k-popup {
    .MuiInputBase-root
    fieldset {
        legend {
            width: 0px;
        }
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        line-height: 12px;
        display: none;
    }
}


.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline legend {
    font-size: $mainFontSize*0.85 !important;
}

.k-textbox-container>.k-label {
    font-size: $mainFontSize*0.85 !important;
    line-height: $mainFontSize*0.85 !important;
    transform: scale(1);
}

.MuiButtonBase-root:not(.MuiIconButton-root),
.MuiInputBase-input,
.MuiInputBase-adornedEnd,
.row-column-conf {
    min-height: $mainHeight !important;
}

.MuiStep-horizontal {
    padding: 0px !important;
}

.MuiDialogTitle-root {
    padding: $mainMargin $mainMargin !important;
    padding-left: $marginDialog !important;
}

.no-radius {
    border-radius: 0px !important;
}

.margin-right {
    margin-right: $mainMargin !important;
}

.margin-left {
    margin-left: $mainMargin !important;
}

.padding-right {
    padding-right: $mainPadding !important;
}

.padding-left {
    padding-left: $mainPadding !important;
}

.adw-icon {
    color: $primarycolor !important;
    width: 30px;
    height: 30px;
    font-size: 30px !important;

    &.lg {
        width: 40px;
        height: 40px;
        font-size: 40px !important;
    }

    &.disabled {
        color: inherit !important;
    }
}

.margin-bottom {
    margin-bottom: $mainMargin !important;
}

.padding-right {
    padding-right: $mainPadding !important;
}

.MuiIconButton-root {
    padding: 0px !important;
}

.MuiAutocomplete-endAdornment {
    //top: calc(50% - #{($mainHeight / 2)}) !important;
    //top: 50% !important;
    transform: translate(0px, -50%);
    top: 14px !important;
    right: 4px !important;
}

.MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    margin-right: 2px;
}

.MuiAutocomplete-root .MuiInputBase-root .MuiInputBase-input {
    padding-right: 25px;
}

.container-component.Mui-disabled,
.MuiInputBase-input.Mui-disabled,
.MuiInputBase-root.Mui-disabled {
    background-color: rgb(242, 242, 242) !important;
}

.MuiInputBase-input,
.MuiInputBase-adornedEnd {
    padding: 0px 0px !important;
    padding-right: 25px !important;
}



.MuiInputBase-input,
.MuiInputBase-adornedEnd,
.MuiOutlinedInput-root {
    padding-left: 5px !important;
}

.MuiInputLabel-outlined:not(.MuiFormLabel-filled) {
    transform: translate(10px, ($mainHeight * 0.20)) scale(1);
}

.display-center,
.toolbar-discount-editor {
    display: flex;
    align-items: center;
}

/** Calendier */
.labels-builder-container {
    width: 100%;
    position: relative;
}

.with-borders {
    border: solid 1px $mainBorder;

    .custom-toolbar-adwtelerikgrid {
        display: none !important;
    }

    .k-grid-header-wrap {
        border-top: 0px;
    }
}

.container-with-borders {
    border: solid 1px $mainBorder;
}

.grouping-grid {
    .with-borders {
        border-bottom: 0px;
    }
}

.k-menu,
.k-tabstrip,
.k-link,
.k-upload {
    font-size: 1rem;
    font-family: "Rubik", sans-serif !important;
}

.k-numerictextbox .k-input-inner {
    font-family: $mainFontNumber !important;
}

.resize-dates-scheduler-checkbox {
    margin-top: $mainMargin !important;
}

.advancedStyles {
    margin-top: $mainMargin;

    .k-tabstrip-items-wrapper.k-hstack {
        button {
            font-weight: bold !important;
            color: #000000;
        }
    }

    .k-tabstrip-items-wrapper.k-hstack>ul.k-tabstrip-items.k-reset>li.k-item {
        height: $mainHeight;
    }

    .k-tabstrip-items-wrapper.k-hstack {
        border-bottom-width: 0px !important;
    }

    .k-tabstrip-items-wrapper.k-hstack>ul.k-tabstrip-items.k-reset {
        display: flex;

    }

    .k-tabstrip-content {
        padding: 0px;
        margin-top: -1px;
        border-top: 1px solid $grey-border !important;
    }

    &.k-tabstrip>.k-content {
        padding: 0px;
        margin-top: -1px;
        border-top: 1px solid $grey-border !important;
    }

    li.k-item.k-active>span.k-link {
        border-bottom: none !important;
        border-left: 1px solid $grey-border !important;
        border-right: 1px solid $grey-border !important;
        border-top: 1px solid $grey-border !important;
        background-color: white !important;
    }
}

.text-color {
    width: 100%;
    display: table;
    line-height: $mainHeight;

    .color-item-container {
        float: left;
        width: 50%;
    }

    .picker-container {
        float: right;
        width: auto;
        display: inline-flex;
        justify-content: left;
        align-items: right;
        height: $mainHeight;
        vertical-align: middle;

        button {
            height: $mainHeight;
        }
    }

    .MuiIconButton-root {
        padding: 0px !important;
    }
}

.colorItem {
    width: 18px;
    height: 18px;
    font-size: $mainFontSize;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    line-height: 1;
    //border-radius: 50%;
    padding: 0px;

    display: inline-flex;
    color: white;
    background-color: rgba(0, 0, 0, 0.54);
}

.configuration-scheduler {
    overflow: hidden;
    width: 100%;

    .with-borders {
        border-top: none;
    }

    .configuration-scheduler-grid {
        width: 100%;

        .custom-toolbar-adwtelerikgrid {
            display: none !important;
        }

        .k-grid-header-wrap {
            border-top: 0px;
        }

        .k-grid {
            font-size: 1rem;
        }

        .k-grid td.column_Badge {
            padding-right: 0;
        }
    }

    .configuration-scheduler-selecteditems {
        padding-inline-end: 17px;
        border-left: 1px solid $mainBorder;
        border-right: 1px solid $mainBorder;
        border-bottom: 1px solid $mainBorder;

        .custom {
            color: rgba(0, 0, 0, 0.54);
            float: left;
        }

        .color-item-container {
            width: 38px;
            text-align: center;
        }

        &:not(.with-selected-items) {
            .text-color {
                visibility: hidden;
            }
        }
    }
}

.configuration-sheduler-labels {
    height: auto;
    display: block !important;
    padding: 0px $mainMargin/2;

    &:hover {
        height: auto !important;
        display: block !important;
        padding: 0px $mainMargin/2 !important;
    }

    .line {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 28px;
        margin-top: 2px;
        margin-bottom: 2px;

        .k-input {
            width: auto !important;
        }

        .k-input-solid {
            background-color: transparent;
        }

        .k-input-inner {
            padding: 2px 2px 2px 6px !important;
            font-size: 1rem !important;
            letter-spacing: inherit;
            max-width: 120px;
        }

        .label-separator {
            padding-top: 2px;
        }

        .k-listbox {
            height: auto;
            min-height: 2rem;
            margin: 0px;
            width: auto;
        }

        .k-list-content {
            overflow-y: hidden;
        }

        .k-list-scroller {
            border-style: none;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            //flex:content;
        }

        li.k-list-item {
            //flex-flow:column wrap !important;
            max-width: 200px;
            padding: 0px;
            margin: 0px;
            margin-top: 2px;

            >div {
                border: solid 1px transparent;
                border-radius: 4px;
                padding: 4px;
                padding-right: 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                .remove-icon {
                    visibility: hidden;
                }

                &:hover {
                    border-color: $dark-blue;
                    background-color: white;

                    .remove-icon {
                        visibility: visible;
                    }
                }
            }


            .remove-icon {
                position: absolute;
                right: 0px;
                top: 6px;
                padding: 0px 2px !important;

                span {
                    font-size: 1rem !important;
                    font-weight: bolder;
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }

        .k-button-icon {
            color: rgba(0, 0, 0, 0.54) !important;
        }

        .picto {
            padding: 0px !important;
            border-radius: 4px;
            letter-spacing: inherit;

            &:hover() {
                border-radius: 4px;
            }
        }

        .picto.small {
            span {
                font-weight: 900;
                font-size: $mainFontSize !important;
            }
        }
    }

    .bar-label {
        margin-left: 4px;

        .k-input {
            width: auto !important;
        }

        .k-input-solid:focus-within,
        .k-picker-solid:focus-within {
            border-color: inherit;
        }

        .inputLeft,
        .inputRight {
            height: 24px;
        }

        .inputRight {


            input {
                text-align: right;
            }
        }

        .inputRight.barProperty {
            margin-left: 24px;
            height: $mainHeight;
            color: inherit;

            input,
            .k-clear-value {
                margin-bottom: 4px;
            }

            button {
                position: absolute;
                top: 14px;
                right: 24px;
                height: 20px;
                width: 16px;
                background-color: white;
            }
        }

        .inputRight.picto {
            height: 20px;
            width: 20px;
            position: absolute;
            top: 46px;
            right: 44px;
            color: inherit;

            .k-button-text {
                line-height: 2;
            }

            .MuiIcon-root {
                font-size: 1.3rem !important;
            }
        }

        .k-input-solid {
            background-color: transparent;
            border-color: transparent;
        }

        .k-input-inner {
            padding: 0px !important;
            letter-spacing: inherit;
            font-family: "Rubik", sans-serif !important;
        }

        .picto {
            letter-spacing: inherit;
            padding: 0px !important;
            border-radius: 4px;
            font-family: "Rubik", sans-serif !important;

            span.MuiIconButton-label {
                font-family: "Rubik", sans-serif !important;
            }

            &:hover() {
                border-radius: 4px;
            }
        }

        .k-clear-value {
            width: 16px;
            height: 16px;
        }

        .k-button-md {
            border: none;
        }

        .k-button-solid-base {
            box-shadow: none;
        }

        .k-button-text {
            height: 24px;
        }

        .k-icon-button.k-button-md {
            padding: 0px;
        }
    }
}

.MuiTabs-root {

    border-bottom: solid 1px $mainBorder ;

    button:hover {
        background: #f0f0f0;
        transition: background-color 0.5s ease;
    }

    .MuiTabs-scrollButtonsDesktop.Mui-disabled {
        width: 0px !important;
        transition: width 0.5s ease;
    }

    .MuiTabs-scrollButtonsDesktop:not(.Mui-disabled) {
        width: 40px !important;
        transition: width 0.5s ease;
    }
}

li.Mui-disabled.customgroup-menuitem {
    opacity: unset !important;

    span {
        opacity: 0.38 !important;
    }
    img {
        opacity: 1 !important;
        pointer-events: auto !important;
    }
}

/** Headers de grilles */
tr.k-filter-row {
    position: relative;

    .container-header-sticky {
        padding: 0px !important;
        padding-left: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    height: $mainHeight !important;

    th {
        margin: 0px;
        overflow: hidden;
        padding: 0px;

        span,
        .listbox {
            border-top: 0;
        }

        .k-dateinput-wrap,
        span.k-input {
            font-weight: normal;
        }

        .k-filtercell {
            padding-left: $mainMargin/2;
            display: flex;
            align-items: center;
        }

        // input.k-textbox,
        // input.k-formatted-value,
        // span.k-input,
        // span.k-select,
        // span.listbox {
        //     height: 21px;
        //     padding-top: 0px !important;
        //     padding-bottom: 0px !important;
        // }
    }
}

/** Messages details */
.adw-row

/*.message_details_leftcombo,
.message_details_full,
.message_details_leftcombo,
.message_details_rightcombo*/
    {
    margin-bottom: $mainRowMargin !important;
}

//.message_details_leftcombo,
.left_column {
    padding: 0 ($mainRowMargin/2) 0 0 !important;
}

//.message_details_rightcombo,
.right_column {
    padding: 0 0 0 ($mainRowMargin/2) !important;
}


.block-container,
.adw-title {
    margin-bottom: $mainMargin !important;
}

.adw-row::after {
    content: "";
    clear: both;
    display: table;
}

.adw-row {
    position: relative;
}

.adw-form-dialog-container {
    height: 70vh;
    position: relative
}

.adw-form-grid-container {
    height: calc(100% - 40px);
    position: relative
}

.adw-form-fields-container {
    padding-top: 5px;
    overflow: auto;
    height: calc(100% - 40px);
    display: block !important;

}

.adw-form-action-container {
    position: absolute;
    right: 0;
    bottom: 0;
}

.adw-form-action-left-container {
    position: absolute;
    left: 0;
    bottom: 0;
}

// .adw-title {
//     margin-left: $mainMargin !important;
// }

.calendar_publications_container {

    .calendar-rectangle,
    .events-calendar {
        height: 310px;
    }

    .row.week {
        height: 41px !important;
    }

    .main-calendar {
        padding: $mainMargin 0
    }

    .day .day-number {
        height: $mainHeight !important;
        width: $mainHeight !important;
    }
}

/** Calendrier */

.legend {
    label {
        font-weight: normal;
        font-size: 0.85rem;
    }

    div {
        font-size: 1.1rem;
        color: #000000DE;
    }
}

.legend label {
    color: rgba(0, 0, 0, 0.54);
}


.box.month-label {
    font-size: 16px;
    text-transform: capitalize;
}

.events-calendar {
    position: relative;
}

.legend-box-action {
    position: absolute !important;
    right: $mainMargin !important;
    bottom: $mainMargin !important;
}

.selection-campaign-row {
    display: flex !important;

    .campaign-autocomplete-container {
        width: 100%;
        margin-right: $mainMargin;
    }
}

.message_details_full {
    padding: 0 0 !important;
}

.MuiAutocomplete-tag,
.chip-element,
.k-group-indicator {
    background-color: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    color: $primarycolor !important;
    border-color: $primarycolor !important;
    height: 18px !important;
    min-height: 18px !important;
    margin-top: $mainMargin/2;
    margin-right: $mainMargin/2;
    margin-bottom: 0;
    margin-left: 0;

    &.MuiButtonBase-root:not(.MuiIconButton-root) {
        margin-top: 3px;
        height: 18px !important;
        min-height: 18px !important;
    }

    .remove-icon {
        font-size: 1rem !important;
        font-weight: bolder;
    }

    .MuiChip-label {
        padding-left: $mainMargin/2;
        padding-right: $mainMargin/1.5;
        font-size: $mainFontSize;
    }

}

.chip-element.missing-element {
    border-color: $orange-color !important;

    >span.MuiChip-label {
        color: $orange-color !important;
    }
}

.chip-element.missing-element-hidden {
    display: none;
}

.MuiChip-root {
    border-radius: 8px;
}

.container-widget {
    height: calc(100% - 50px);
    margin-top: 50px;

    .widget-expendable {
        overflow-y: auto;
        max-height: 0;
        transition: max-height 1s ease-in-out;

        &.open {
            max-height: 600px;
        }
    }

    .widget-chips-scroll {
        margin-top: 10px;
        overflow-y: auto;
        height: calc(100% - 50px);

        .container-chip-overflow {
            display: flex;
            flex-wrap: wrap;
            min-height: 60px;
            max-height: 60px;
            overflow: hidden;
            transition: max-height 1s ease-in-out;

            &.open {
                max-height: 600px;
            }

            >div {
                width: 100%;
            }

        }

        .chip-element {
            width: auto;
            max-width: -webkit-fill-available;

            &.chip-element-frozen {
                color: rgba(0, 0, 0, 0.87) !important;
                border-color: rgba(0, 0, 0, 0.87) !important;
            }

            .MuiChip-label {
                text-overflow: ellipsis;
            }
        }

        .button-chip-overflow {
            position: relative;
            height: 0;

            span {
                position: absolute;
                right: 50%;
                top: -2px;
            }
        }
    }

    .widget-button {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px;
    }
}


.base_tarifs {
    width: 100%;
}

/** DATES */


// .k-dateinput-wrap input,
// .k-widget.k-dateinput,
// .k-picker-wrap.k-picker-wrap,
// .k-dateinput-wrap {
//     height: $mainDateInputHeight;
// }

.MuiDrawer-root>.MuiPaper-root {
    overflow: visible !important
}

// .k-dateinput-wrap input {
//     padding-top: 0px;
//     padding-bottom: 0px;
// }

.label-simpledatepicker {
    position: absolute;
    top: -6px
}

.k-grid
td.EditableInlineCell {
    &.editable:hover {
        border: 1px solid $mainBorder !important;
        border-color: #000000 !important;
        border-width: 1px !important;
        border-left-color: unset !important;
        border-radius: 5px;
    }
}

.custom-date-input {
    input {}
    &.k-invalid {
        input {
            color: $errorcolor;
        }
    }
}

.custom_daterangepicker-container {

    // width: 100%;
    // .k-textbox {
    //     height: calc(1.125em + 16px);
    // }
    // .custom_daterangepicker {
    //     float: left;
    //     width: 100%;
    //     .label-simpledatepicker {
    //         font-size: 0.75rem;
    //         color: rgba(0, 0, 0, 0.54);
    //     }
    //     .content-simpledatepicker {
    //         // position: absolute;
    //         // bottom: 0px;
    //         height: 34px;
    //         .custom-date-input {
    //             width: 100%;
    //             &.k-invalid {
    //                 input {
    //                     color: $errorcolor;
    //                 }
    //             }
    //             input {
    //                 // padding: 6px 0 0 0;
    //                 border: none;
    //                 width: 100%;
    //             }
    //         }
    //         .k-select {
    //             padding: 6px 4px 0 4px;
    //         }
    //     }
    // }
    .clear-date-button {
        color: $menu-color;
        position: absolute;
        right: 25px;
        padding: 2px 4px;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        text-align: center;
        cursor: pointer;
    }
}

.custom_daterangepicker-container {
    .k-textbox-container {
        padding-top: 0px;
    }
}

.no-padding,
.k-grid-content td.no-padding {
    padding: 0px !important;
    padding-left: 0px !important;
}

.k-grid td.no-border {
    border-width: 0 0 1px 0 !important;
}

.no-margin {
    margin: 0px !important;
}

.k-widget.k-maskedtextbox.k-dateinput-wrap.custom-date-input {
    display: flex;
    align-items: center;
}

.custom-input-date,
.custom_daterangepicker-container,
.custom_daterangepicker {

    position: relative;
}

td {

    .custom-input-date,
    .custom_daterangepicker-container,
    .custom_daterangepicker {
        height: initial;
        position: initial;

        .k-select,
        input {
            padding: initial !important;
        }
    }
}

.confpanel_content {
    padding: 0 $mainMargin * 2;
}

.drawer_title {
    border-bottom: 1px solid $mainBorder;
    padding: $mainMargin + 3 $mainMargin * 2;
}

.confpanel_content {
    padding-top: $mainMargin;
    height: calc(100% - 120px);
    overflow: overlay;

    >div.ModeleColumnsCreator {
        display: flex !important;
        flex-direction: column !important;
    }
}

.drawer_title h3 {
    margin: $mainMargin 0 !important;
}

.mainmargin-container {
    padding: $mainMargin;
}

.ColumnEditor-column-container {
    margin-top: $mainMargin;

    min-height: $mainHeight*2;
    overflow: auto;
    width: 100%;
    float: left;
    width: calc(100% - 20px);
}

.panel-conf-container {
    margin-top: $mainMargin;
    padding-top: $mainMargin;
    border-top: 1px solid #DDDDDD;
}

.action-modeles-buttons {
    display: flex;
    align-items: center;

    .MuiIconButton-label {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
}

/** ligne des modèles de colonnes */
.row-column-conf {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;

    .row-column-conf-text {
        margin-left: $mainMargin !important;
    }

    .row-column-conf-actions {
        position: absolute;
        right: 0px;
    }
}

.campaign-cell {
    .campagain-cell-name {
        width: calc(100% - 45px);
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .messages-by-campaign-icon {
        position: absolute;
        right: 0;
    }

    .warning_color {
        width: 20px;
        height: 20px;
    }
}

.row-column-conf:hover {
    background: #f0f0f0 !important;
}

.view-icons {
    position: absolute;
    top: 3px;
    right: $mainMargin + 7;
}

.secondary-container-component {
    display: flex;

    .container-component {
        border: 0px;

        >label {
            display: none;
        }

        .daterangepicker_container {
            height: $mainHeight;
            border-bottom: 1px solid rgba(0, 0, 0, 0.23);
        }
    }

    button.picto {
        margin: 0px;
        margin-right: $mainMargin;
        padding: 0px !important;
    }

    .modele-selector-root {

        border-bottom: 1px solid rgba(0, 0, 0, 0.23);

        .modele-selector-icon-filter {
            display: flex;
            position: absolute;
        }

        .modele-selector-container {

            width: 100%;

            fieldset {
                border: 0px;
            }

            label {
                display: none;
            }

            input {
                padding-left: 35px !important;
            }
        }

        .action-modeles-buttons {
            display: none;
        }
    }
}

li.MuiBreadcrumbs-li {

    li.MuiBreadcrumbs-separator,
    a,
    p {
        font-size: 1.2rem !important;
    }

    p {
        color: rgb(64, 64, 64)
    }

    a {
        &.MuiLink-underlineHover {
            &:hover {
                box-shadow: none !important;
                text-decoration: none !important;
            }
        }

        @extend .no-shadow;
        @extend .picto;
        @extend .custom_btn_secondary;
    }
}

.k-badge-edge {
    z-index: 10;
}

.k-calendar-content {

    td.k-calendar-td {
        border: 0px;
        padding: 0px;
    }

    .k-link {
        border: 0px;
    }
}

.k-invalid {
    fieldset {
        border-color: $errorcolor;

        &:hover {
            border-color: $errorcolor !important;
        }
    }
}

.text-form-container {

    button {
        height: 25px;
        width: 25px;
    }
}

.full-width {
    width: 100%;
}

.tabpanel_fullwidth {
    >.k-tabstrip-content {
        overflow: hidden;
    }

    >.k-tabstrip-content>.k-animation-container {
        width: 100%;
        overflow: visible;
    }
}

.k-list-md {
    font-size: $mainFontSize;
}

.k-listbox .k-item {
    padding: 0 $mainMargin;
}

.listbox-rights {
    width: 100%;

    /*
    .k-list-scroller {
        overflow:overlay;
    }*/
    .k-list-scroller.k-selectable {
        overflow: hidden;
    }

    .k-list-content {
        overflow: overlay;
    }
}

.listitem-label-rights {
    span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    float: left;
    display: flex;
    align-items: center;
    height: $mainHeight;
    width: calc(100% - 48px);
}

.k-switch-off .k-switch-track {
    background-color: $dark-grey;
}

.k-switch-on .k-switch-track {
    background-color: $medium-grey;
}

.k-switch-on:hover .k-switch-thumb {
    background-color: $medium-blue;
}

.k-switch-on .k-switch-thumb {
    background-color: $dark-blue;

    &:hover {
        background-color: $medium-blue;
    }
}

.Breadcrumbs {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    .MuiBreadcrumbs-ol {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline;

        .MuiBreadcrumbs-li,
        .MuiBreadcrumbs-separator,
        .current-view,
        p {
            display: inline;
        }
    }

    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition-property: color,
    transform;
    transition-duration: 200ms,
    200ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1),
    cubic-bezier(0, 0, 0.2, 1);
    transition-delay: 0ms,
    0ms;

    .MuiBreadcrumbs-li a:not(:hover) {
        background-color: transparent !important;
    }

    .MuiBreadcrumbs-li a:hover {
        background-color: $light-blue !important;
    }

    &:not(.element-selected) {
        // transform: translate(14px, 13px) scale(1);
    }

    &.element-selected {
        transform: translate(0px, (($mainHeight) * -1) + ($mainMargin / 2) - 2px) !important;
        font-size: 11.9px !important;
        z-index: 12;

        .MuiBreadcrumbs-li {
            display: contents;
        }

        .MuiBreadcrumbs-ol {
            background-color: white;
            width: fit-content;
            padding: 0px 5px;
        }

        .MuiBreadcrumbs-ol,
        .MuiBreadcrumbs-li {
            height: 19px;

            p.current-view,
            a {
                margin: 0px !important;
                font-size: 13px !important;
            }
        }

    }
}

.custom-toolbar-adwtelerikgrid-fixed {

    // padding: $mainMargin 5px;
    height: $mainHeight;

    &.toolbar-adwtelerikgrid-selecteditems {
        // background-color: $light-blue;
    }

    .custom-toolbar-adwtelerikgrid-content {
        position: relative;
    }
}

.datalinksimilarities {
    .custom-toolbar-adwtelerikgrid-fixed {
        height: 0;
    }
}

.title-CustomGroupButton {
    height: $mainHeight;
    align-items: center;
    display: flex;
    padding-left: $mainMargin;
    padding-right: $mainMargin;
    white-space: nowrap;
}

.title-CustomGroupButton {
    color: white;
    background-color: $dark-blue;
    cursor: default;
}

.k-tabstrip-items-wrapper .k-item.k-active::after {
    border-color: $dark-blue;
}

.MuiInputLabel-formControl {
    top: 0;
    left: 0;
    position: absolute;
    color: rgba(0, 0, 0, 0.54);
}

.half-width {
    flex-basis: 50%;
    max-width: 50%;
}

.count-elements {
    height: $mainHeight;
    display: flex;
    align-items: center;
}

.checkbox-activation {
    >div {
        position: relative;

        .warning-message {
            top: 10px;
            right: 30px;
            left: auto;
            z-index: 1;
        }
    }
}

.warning-message {
    position: absolute;
    right: 5px;
    top: -6px;
    background: white;
    z-index: 1;
    padding-left: 5px;
    padding-right: 5px;
    color: #F4611D !important;
    display: flex;

    >span {
        font-size: 12px;
        vertical-align: middle;

        &.material-icons {
            font-size: $mainFontSize;
        }
    }
}

.CustomEditor {
    >.k-editor-content {
        >iframe {
            height: 130px !important;
        }
    }
}

.negociation-tabstrip-content {
    .k-editor-content {
        >iframe {
            height: 100px !important;
        }
    }
}

.k-input,
.k-dateinput,
.k-dateinput-wrap {
    transition: unset !important;
}

.hide-element {
    display: none;
}

.grouping-grid {
    input.input-Decimal {
        font-family: $mainFontNumber !important;
        text-align: end;
        padding-right: $mainMargin !important;
    }

    td.number-cell-data {
        padding-right: $mainMargin * 2 !important;
    }
}

.performance-grid,
.custom-tree-list {

    .number-cell-data {
        padding-right: $mainMargin;
    }

    td,
    th,
    tr,
    thead.k-grid-header {
        height: $mainHeight;
        padding: 0px;
        padding-left: $mainMargin;
    }

    thead.k-grid-header>tr>th>span {
        height: $mainHeight;

    }

    tr>td {
        max-width: 150px;
    }

    .border-left {
        border-left: solid 1px $mainBorder !important;
    }

    tr.row-disabled {
        td {
            background-color: rgb(189, 189, 189) !important;
        }

        &:hover {

            >td.k-grid-content-sticky,
            td {
                background: rgb(189, 189, 189) !important;
            }
        }
    }
}

.performance-grid {
    th {
        justify-content: center;
    }

    .k-grid-header thead>tr:not(:only-child)>th.border-left,
    td.border-left {
        border-left-width: 1px !important;
        border-left-color: rgba(0, 0, 0, 0.12) !important;
    }
}

.custom-tree-list.link-mgr {

    td {
        border: 0px;
    }

    .k-grid-header .k-filter-row th.k-grid-header-sticky {
        border-right: solid 1px rgb(223, 223, 223) !important;
    }

    td,
    th.k-header.k-grid-header-sticky {
        border-right: solid 1px rgb(223, 223, 223) !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    td,
    th,
    thead.k-grid-header,
    .k-filtercell,
    thead.k-grid-header>tr>th>span,
    tr.k-filter-row,
    tr.k-filter-row>th {
        // height: 30px !important;
        line-height: unset;
    }

    .k-filtercell-operator {
        display: flex;
    }

    .k-picker-wrap.k-picker-wrap {
        height: 25px !important;
    }
}

.DialogContent-container {
    padding: 0px !important;
    margin-right: $marginDialog;
    margin-left: $marginDialog;
    max-height: 72vh;
    margin-bottom: $marginDialog;
    padding-top: $mainMargin !important;

    &.messages {
        max-height: inherit;
        height: inherit;
        margin: 0px;
        padding: 0px !important;
        border-radius: inherit;
    }
}

.CellEditValidation-component {
    border-top: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    gap: $mainMargin;
}

.title-CustomLayout {
    padding-right: $mainToolIconsWidth;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    top: 0;
    padding-top: $mainMargin * 0.75;
    padding-bottom: $mainMargin * 0.75;
    width: calc(100% - 36px);
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    /* Pour les navigateurs basés sur WebKit */
    -ms-user-select: none;
    /* Pour Internet Explorer */
}

span.MuiButtonBase-root.MuiCheckbox-root.css-imrjgg-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px;
    padding-left: $mainMargin;
    padding-right: $mainMargin;
}

.ProjectConfigurationWidget {
    padding: $mainMargin;
}

.home_container {
    overflow: hidden;
    height: calc(100% - #{$mainToolBarHeight}) !important;

    .DashBoardGridLayout-container {
        height: calc(100vh - 135px);
        overflow: overlay;
        overflow-x: hidden;
    }

    .widgets_container {
        padding: $mainMargin;

        .title {
            font-size: 1.5*$mainFontSize;
            margin-bottom: $mainMargin;
            display: flex;
            align-items: center;

            .SearchWidgets {
                margin-left: auto;
            }
        }

        .DashBoardGridLayout-children {
            margin-right: 1px;
        }
    }

    .calendar_container {
        padding: $mainMargin;

        .title {
            font-weight: 500;
            display: flex;
            align-items: center;
            height: $mainHeight;
            font-size: 1.5*$mainFontSize;
            margin-bottom: $mainMargin;
        }

        .events-calendar {
            padding: $mainMargin;
        }
    }

    div.k-chart.k-widget {
        height: 100%;
    }

}

.dashboard-block-header {
    flex: 1; /* Each block takes up equal space */
    display: flex;
    flex-direction: row; /* To stack content vertically */
    align-items: center; /* Align items to the left */
    justify-content: space-between; /* Center content vertically */
    padding: 10px; /* Add some padding inside the blocks */
    box-sizing: border-box; /* Ensure padding is included in the block size */
    border-radius: 8px;
    .dashboard-block-title {
        font-size: 1.5*$mainFontSize;
        font-weight: 500;
        margin-bottom: $mainMargin;
    }

    .adw-icon.header-icon {
        background: rgb(0, 155, 206);
        color: white !important;
        border-radius: 20px;
        padding: 7px;
        height: 20px;
        width: 20px;
        font-size: 20px !important;
    }

    .text-desc {
        margin-top: 5px;
    }

    .subtitle-dashboard-block-header {
        font-size: 1.2*$mainFontSize;
        font-weight: 500;
        margin-bottom: $mainMargin;
    }
}

td.column_Layer {

    >span {
        padding: 5px;
        border-radius: 5px;
    }

    &.cellule_Rights {
        >span {
            color: white;
            background: #2cbf37;
        }
    }

    &.cellule_Security {
        >span {
            color: white;
            background: #e45a1a;
        }
    }

    &.cellule_User {
        >span {
            color: white;
            background: #7aacd9;
        }
    }

    &.cellule_Sellsy,
    &.cellule_MediaOcean {
        >span {
            color: white;
            background: #2227c1;
        }
    }

    &.cellule_MAP {
        >span {
            color: white;
            background: #2227c1;
        }
    }

    &.cellule_MasterData {
        >span {
            color: white;
            background: #855002;
        }
    }

    &.cellule_ConnexionLost {
        >span {
            color: white;
            background: rgb(255, 0, 0);
        }
    }

    &.cellule_Business {
        >span {
            color: white;
            background: #7ad97f;
        }
    }

    &.cellule_DataBase {
        >span {
            color: white;
            background: #ce5b39;
        }
    }

    &.cellule_Performance {
        >span {
            color: white;
            background: #c55760;
        }
    }

    &.cellule_Task {
        >span {
            color: white;
            background: #83ca49;
        }
    }

    &.cellule_Medialand {
        >span {
            color: white;
            background: #4d8b50;
        }
    }

    &.cellule_SocketMessage {
        >span {
            color: white;
            background: #d9d77a;
        }
    }

    &.cellule_SSO {
        >span {
            color: white;
            background: #66c7e4;
        }
    }

    &.cellule_Error,
    &.cellule_OfferError,
    &.cellule_TSMError {
        >span {
            color: white;
            background: #e46666;
        }
    }

    &.cellule_Worker {
        >span {
            color: white;
            background: rgb(0, 212, 255);
            background: radial-gradient(circle, rgba(0, 212, 255, 1) 12%, rgba(60, 52, 189, 1) 75%, rgba(9, 9, 121, 1) 100%);
        }
    }

    &.cellule_Ava {
        >span {
            color: white;
            background: rgb(109, 164, 175);
        }
    }

    &.cellule_Boot {
        >span {
            color: white;
            background: rgb(187, 99, 36);
            background: radial-gradient(circle, rgba(187, 99, 36, 1) 19%, rgba(28, 15, 6, 1) 100%, rgba(255, 188, 0, 1) 100%);
        }
    }

    &.cellule_LockingEventLoop {
        >span {
            color: white;
            background: rgb(187, 99, 36);
            background: radial-gradient(circle, rgb(187, 36, 36) 19%, rgba(28, 15, 6, 1) 100%, rgb(255, 123, 0) 100%);
        }
    }

    &.cellule_Worker {
        >span {
            color: white;
            background: rgb(0, 212, 255);
            background: radial-gradient(circle, rgba(0, 212, 255, 1) 12%, rgba(60, 52, 189, 1) 75%, rgba(9, 9, 121, 1) 100%);
        }
    }

    &.cellule_SocketClient {
        >span {
            color: white;
            background: rgb(184, 122, 255);
        }
    }

    &.cellule_Mail {
        >span {
            color: white;
            background: rgb(255, 122, 226);
        }
    }

    &.cellule_Boot {
        >span {
            color: white;
            background: rgb(187, 99, 36);
            background: radial-gradient(circle, rgba(187, 99, 36, 1) 19%, rgba(28, 15, 6, 1) 100%, rgba(255, 188, 0, 1) 100%);
        }
    }
}

.k-splitter {
    line-height: unset;
}

.main-padding {
    padding: $mainMargin;
}

.cover-vertexauto {
    height: 25px;
    position: absolute;
    right: 40px;
    top: (36 - 25) / 2;

    &:hover {
        transition: height 0.25s ease-in;
        transition: top 0.25s ease-in;
        height: 150px;
        top: -50px;
        z-index: 10000;
    }
}

.MuiAutocomplete-popper {
    .MuiAutocomplete-paper {
        margin: 0px !important;
    }

    .MuiAutocomplete-listbox {
        ul {
            margin: 0px;
        }
    }
}

.CellNumberComponent-editing-cell {
    input {
        text-align: right;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 25px;
    }

    .k-numeric-wrap {
        flex-flow: row-reverse;
    }
}

.focus-background-element {
    position: absolute;
    z-index: 1301;
    background: #000000;

    &.m-fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.m-fadeIn {
        visibility: visible;
        opacity: 0.4;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

}

.k-animation-container.k-animation-container-relative.k-animation-container-shown {
    z-index: 1400 !important;

    .k-list-md .k-list-item,
    .k-list-md .k-list-optionlabel {
        font-size: 13px;
        padding: 6px 4px;
        line-height: 1.25;
    }

    .k-list-md .k-list-group-sticky-header {
        color: $primarycolor;
        font-weight: 500;
    }

    .k-list-item-group-label {
        background-color: $primarycolor;
        padding: 2px 4px;
    }

    .k-list-item.k-first::before,
    .k-first.k-list-optionlabel::before {
        color: $primarycolor;
    }
}

.custom-list {
    max-height: 200px;
}

.modelecreator-tab {
    .k-grid-header {
        display: none;
    }

    td {
        padding-top: 0;
        padding-bottom: 0;
        height: $mainHeight;
    }

    .k-grid-content {
        overflow-y: auto !important;
    }
}

.k-input.k-numerictextbox {
    background-color: transparent;
    padding-left: 5px;
    padding-right: 0px;

    input {
        padding-left: 0px;
        padding-right: 0px;
    }

    .k-input-spinner.k-spin-button {
        button {
            padding: 0px;
        }
    }
}

.k-filtercell {

    .k-input.k-datepicker {
        background-color: transparent;
        font-size: $mainFontSize
    }

    >span input,
    input .k-input-md .k-input-inner,
    >span button.k-button {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    >span input,
    .k-dateinput.k-input input.k-input-inner,
    .k-input.k-numerictextbox {
        background-color: transparent;
        padding-left: 5px;
        padding-right: 0px;
    }

    >span {

        .k-dropdownlist,
        button.k-button {
            background-color: transparent;
            border: 0px;

            padding-left: 0px !important;
            padding-right: 0px !important;

            &.k-disabled {
                display: none;
            }
        }
    }
}

span.k-textbox-container {
    display: contents;
}

.menu_custom_btn_primary {
    height: $mainHeight !important;

    span.k-link.k-menu-link {
        height: $mainHeight !important;
        color: white !important;
        font-family: $mainFontNumber;
        font-weight: 500;
        background-color: #009BCE !important;
    }
}

.k-grid table tbody tr:hover:not(.k-filter-row, .k-detail-row) {
    background-color: rgba(0, 0, 0, 0.07) !important;
}

.k-grid table tr:not(.k-detail-row) {
    &:hover {
        background-color: inherit;
    }
}

.k-picker-solid {
    width: 0px;
    padding: 0px;
}

.k-picker-md.k-icon-picker .k-input-inner {
    height: auto;
}

.k-colorpicker {

    .k-button-rectangle {
        display: none;
    }
}

.primary-color {
    color: $dark-blue
}

.newkpis {
    display: flex;
    justify-content: space-between;

    &.greater {
        color: $errorcolor;
    }

    &.lower {
        color: $validationcolor;
    }

    &.equal span {
        color: $dark-grey;
    }
}

.tooltip-agreement {
    td {
        &.number-cell {
            text-align: right;
        }
    }
}

.duplicate-messages-container {
    row-gap: $mainMargin;
}

.discount-tabstrip {

    font-family: $mainFontNumber !important;

    .editable-cell-discount {
        width: 100%;

        &:hover {
            border-radius: 1px;
            background-color: rgba(0, 0, 0, 0.07);
            cursor: text;
        }
    }

    .k-content {
        border: solid 1px rgba(0, 0, 0, 0.12);

        >div,
        &.k-active {
            width: 100%;
        }

        .discount-editor-array-container {
            border-left: 0px;
            border-right: 0px;
            width: 100%;

            span.discountGridRowTitle_cell {
                font-family: $mainFontNumber !important;
            }
        }

        tr.k-grid-norecords td {
            font-family: $mainFontNumber !important;
            ;
        }

        padding-top: 0px !important;
    }
}

.custom-MultiSelectTree-popup {

    .k-treeview {
        max-height: 200px;
        overflow: auto;
        font-family: "Rubik", sans-serif !important;
        font-size: $mainFontSize !important;
    }
}

.multiAdvertisersMode-component {
    position: absolute;
    right: $marginDialog;
    top: $marginDialog;

    input {
        margin-left: $mainMargin;
    }

    label {
        font-family: 'Rubik' !important;
        font-size: $mainFontSize !important;
        font-weight: normal;
    }

    .k-radio:checked {
        border-color: $dark-blue;
        color: $dark-blue;
        background-image: none;
        background-color: $dark-blue;
        box-shadow: inset 0px 0px 0px 2px white;
    }
}

span.k-switch {
    height: 100%;
    margin-right: 5px;
    margin-left: 5px;
}

label.MuiFormControlLabel-root {
    padding-left: 1px !important;
}

.navigation_drawer_list_subitem_submenu {
    padding-left: 19px;
}

.loginCard_content_form_buttonContainer {
    position: relative
}

.grid_title {
    position: relative;
    width: 100%;
}

.paper-container {
    border-radius: 10px !important;
}

.message_dialog,
.campaign_dialog {

    .grid_title {
        font-size: 18px;
        border-bottom: 2px solid #a5a5a544 !important;

        div {
            padding: $marginDialog;
        }
    }

    .message_dialog_content {
        .grid_title {
            position: sticky;
            top: 0;
            z-index: 11;
            background-color: white;
        }
    }

    .MessageEditorOverview {

        .overview-container {
            margin-top: 5px;
        }

        .overview-field {
            font-weight: 500;
        }

        .adw-row {
            margin-top: 5px;
        }
    }

    .adw-title {
        font-weight: 500;
    }

    .adw-row {
        margin: $mainHeight / 2 !important;

        &.advertiser-hierarchy-component {
            margin: 0px !important;
        }
    }
}

.panel_disable {

    span.k-switch {
        display: none;
    }

    cursor: not-allowed;
    position: relative;

    input,
    .MuiInputBase-root {
        background: #f2f2f2 !important
    }

    span,
    .adw-title,
    .grid_title {
        color: #0000001f !important;
    }

    .text_description {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-style: italic;
        font-weight: 500;
    }

    .panel_disable_locker {
        z-index: 2;
        /*background: #0000000d;*/
        position: absolute;
        height: 100%;
        width: 100%;
    }
}

.message_dialog_content {
    .panel_disable {
        display: none;
    }
}

.anchor-field {
    cursor: pointer;

    &:hover {

        text-decoration: underline !important;

    }
}

.scheduler-text-cell {
    height: 12px;
}

.scheduler-text-container {}

.warning-element {

    position: relative;

    fieldset.MuiOutlinedInput-notchedOutline {
        //border-color: $orange-color !important;
    }

    label {
        //color: $orange-color !important;
    }

    .WarningDiscount-legend {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 1;
        color: $orange-color !important;
        font-size: 12px;
    }

    .WarningMM-legend {
        position: absolute;
        right: 5px;
        bottom: -6px;
        background: white;
        z-index: 1;
        padding-left: 5px;
        padding-right: 5px;
        color: $orange-color !important;
        font-size: 12px;
    }
}

.modele-selector-container {
    width: calc(100% - 80px);
}

.separator-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.k-grid-footer td.footer-cell-total {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-left-width: 1px !important;

    span {
        font-weight: normal;
    }
}

.k-daterangepicker .k-dateinput {
    width: unset !important;
}

.modele-selector-icon-filter {
    display: none;
    color: rgba(0, 0, 0, 0.54);
}

.currencies-toolbar {
    .ModeleSelector-container {
        display: none !important;
    }
}

.badge-template {
    .badge-template-icon {
        .k-badge {
            width: 10px;
            height: 10px;
            background-color: $dark-blue;
            border-color: $dark-blue;
        }

        position: absolute;
        top: 5px;
        right: 5px;
    }
}

td.td-command-cell {
    padding: 0px !important;
}

.discountGridRowTitle {
    font-weight: bold;
}

.netdiscount-container {
    width: 100%;
    margin: 0px;
    .MuiInputBase-adornedEnd {
        input {
            min-height: 20px !important;
            width: 100%;
        }
    }

    .k-input {
        padding-top: 10px;
    }

    input {
        font-family: $mainFontNumber !important;
    }

    .container-component {
        margin-left: $mainMargin;
        width: 100%;

        label {
            
        }
    }

    fieldset {
        border: 0px;
    }
}

.celllockvaluecomponent {
    input.k-checkbox {
        width: 20px;
        height: 20px;
    }
}

.flex {
    display: flex;

    &.center {
        align-items: center;
    }

    &.reverse {
        flex-direction: row-reverse;
    }

    &.grow {
        flex-grow: 1;
    }

    &.between {
        justify-content: space-between;
    }

    &.column {
        flex-direction: column;
    }
}

.position-relative {
    position: relative;
}

.width {
    width: 100%;
}

.FooterCellTotalElement {
    display: flex;
    align-items: center;
    float: right;
}

.externalclients-toolbar {
    height: 0px;
}

div.k-expander {
    position: relative;

    .k-expander-header {
        height: $mainHeight;
    }
}

.lines-separator-row {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
}

.lines-AdvertiserHierarchyComponent {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
    top: -26px;

    .separator-hierarchy {
        height: $mainHeight - 1;
        top: -1px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .separator-hierarchy-container {
        position: absolute;
        top: $mainHeight / 2;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

td {
    >span.crossedtable-cell {
        margin-right: 1px;
        border-left: 0px !important;
    }
}

.main-margin-container {
    padding: $mainMargin;
}



.customexpander-arrow-container {
    position: relative;

    .filter-title {
        font-weight: bold;
    }

    .customexpander-title {
        // color: rgba(0, 0, 0, 0.54) !important;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translateY(-50%);
        width: 90%;

        span {
            position: relative;

            .k-badge {
                width: 10px;
                height: 10px;
                background-color: $dark-blue;
                border-color: $dark-blue;

                &.k-top-start {
                    top: 2px;
                    left: 7px;
                }
            }
        }
    }

    .customexpander-arrow {
        >span.material-icons {
            cursor: pointer;
            font-size: 18px !important;
            // color: rgba(0, 0, 0, 0.54);
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
        }
    }
}

.pricing-locker-icon {
    cursor: pointer;

    :hover {
        color: $dark-blue;
        transition: color 0.1s;
    }
}

.locked_pricing_container {
    .menu_custom_btn_primary {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.54) !important;

        span.k-link.k-menu-link {
            background-color: rgba(0, 0, 0, 0.12) !important;
            color: rgba(0, 0, 0, 0.54) !important;
        }
    }

    color: gray !important;

    input {
        color: gray !important;
    }
}

.footerCellLoading {
    color: lightgray;
}

.locked_pricing {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(107, 107, 107, 0.11);
    z-index: 10;
    cursor: not-allowed;
}

.pdf-exported-page {
    .only-at-pdf-export {
        display: block !important;
    }

    .ignored-at-pdf-export {
        display: none !important;
    }
}

.warning-duplicate-icon {
    position: absolute;
    top: 0.5rem;
    right: 35px;
    color: $orange-color;

    span {
        font-size: 20px;
    }
}

.input-with-badge {
    input {
        padding-left: 20px !important;
    }
}