.ctvResultsMainGrid {
    margin: 15px;
}

.ctvKPI {
    background-color: #03294F;
    border-radius: 15px;
    padding: 15px;
}

.ctvKPIText {
    color: white;
}

.ctvKPITitle {
    color: white;
    font-size: 16px !important;
}

.ctvKPIValue {
    color: white;
    font-size: 26px;
}

.ctvChartContainer {
    border: 1px solid gray;
    border-radius: 15px;
    padding: 15px;
}

.ctvChartTitle {
    color: #03294F;
}