@import './colors.scss';
@import './baseTheme.scss';
@import "@progress/kendo-font-icons/dist/index.css";
/** CSS From Node 20 **/

.MuiTooltip-tooltip {
    margin: 0px;
    padding: 0px !important;
    color: unset !important;
    font-size: $mainFontSize !important;
}

table.k-table.k-grid-header-table, .custom-tree-list table.k-table {

    .k-picker-solid::after {
        border: none !important;
    }

    .k-table-th {
        height: $mainHeight !important;
        padding: 0px !important;
    }

    .k-input-button {
        padding: 0px !important;
    }

    .k-filtercell-operator {
        span {
            border: none !important;
        }

        button:disabled {
            background-color: transparent !important;
            display: none !important;
        }

        button {
            border: none !important;
            padding: 0px !important;
            box-shadow: none !important;
        }
    }

    .k-filtercell {
        padding-left: 0px !important;
    }

    .k-dateinput,
    .k-numerictextbox,
    input.k-input-inner {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .k-input {
        background: transparent !important;
    }

    th.k-header {
        padding-left: $mainMargin !important;
    }

    .k-filtercell-wrapper {
        gap: 1px !important;
    }

    span.k-input-spinner.k-spin-button {
        display: none !important;
    }
}

span.k-daterangepicker {
    gap: 0px !important
}

.k-table-td {
    padding: 0px !important;
}

.k-animation-container {
    z-index: 1700 !important;
}

.k-gantt-toolbar {
    display: none;
  }

.discount-tabstrip {
    .k-tabstrip-content {
        padding: 0px !important;
        > .k-animation-container {
            width: 100% !important;
        }
    }
}