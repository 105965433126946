@import "../../../../../styles/baseTheme.scss";
@import '../../../../../styles/colors.scss';

$heightOfPicker: $mainHeight * 4;
//$heightOfDataPicker: $mainHeight * 10;
$heightOfFullDataPicker: $mainHeight * 25;

.group-form-container {
    height: calc(100% - 28px);
    overflow-y: auto;
}

.ofDataGroup-picker-container,
.tcd-dimensions-picker-container {
    padding-top: $mainMargin !important;
    padding-left: 0px !important;
    height: auto !important;
}

tr.k-pivotgrid-row {

    /** Header **/
    th.k-pivotgrid-cell.k-pivotgrid-header-root,
    th.k-pivotgrid-cell {
        >span {
            font-size: 10px !important;
        }

    }

}

.group-ofData-grid {

    .k-grid-content {
        overflow: auto;
        overflow-y: auto !important;
    }

    .add-indicateur-button {
        background-color: unset !important;
        width: 100%;
    }

    .k-grid-header-wrap {
        border-top: 0px;
    }

    .full-ofData-grid {
        height: $heightOfFullDataPicker;
    }

    td.hasGroups {
        font-weight: 500;
        color: $medium-blue;
    }

    tr.k-master-row {
        >td {
            border: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: $mainHeight;
            padding-right: 0px;
        }
    }

    td.cell-disable {
        color: rgba(0, 0, 0, 0.26) !important;

        span {
            color: rgba(0, 0, 0, 0.26) !important;
        }
    }
}

.ofDataGroup-picker {

    .k-grid-content {
        overflow: auto;
        overflow-y: auto !important;
    }

    .k-grid-norecords {
        width: 100% !important;

        .k-grid-norecords-template {
            border: none;
            width: auto;
        }
    }

    .ofData-grid {
        //height: $heightOfDataPicker;
        border: 0px !important;
    }

    .full-ofData-grid {
        height: $heightOfFullDataPicker;
        border: 0px !important;
    }

    .k-header {
        display: none;
    }

    .k-grid-header-wrap {
        border-top: none;
    }

    tr.k-master-row {
        >td {
            border: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: $mainHeight;
            padding-right: 0px;
        }
    }

    td.cell-disable {
        color: rgba(0, 0, 0, 0.26) !important;

        span {
            color: rgba(0, 0, 0, 0.26) !important;
        }
    }
}

.tcd-dimensions-picker {

    .k-grid-content {
        overflow: auto;
        overflow-y: auto !important;
    }

    .add-indicateur-button {
        background-color: unset !important;
        width: 100%;
    }

    .indicateurs-grid {
        height: $heightOfPicker;
        border: 0px !important;
    }

    .k-grid-norecords {
        width: 100% !important;

        .k-grid-norecords-template {
            border: none;
            width: auto;
        }
    }

    .k-grid-header {
        display: none;
    }

    tr.k-master-row {
        >td {
            border: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: $mainHeight;
            padding-right: 0px;
        }
    }

    td.cell-disable {
        color: rgba(0, 0, 0, 0.26) !important;

        span {
            color: rgba(0, 0, 0, 0.26) !important;
        }
    }
}